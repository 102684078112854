@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}

@mixin font-size($size, $base: 16) {
  font-size: $size; // fallback for old browsers
  font-size: ($size / $base) * 1rem;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(animate) {
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.ttf') format('truetype'),
    url($file-name + '.svg##{$font-name}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }
  @if $default {
    #{$property}: #{$value};
  }
}

@mixin center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
}

@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin transform($params) {
  @include css3-prefix('transform', $params);
}

@mixin box_sizing($params) {
  @include css3-prefix('box-sizing', $params);
}


/// Transition
/// @param {List} $properties - Properties
/// @require {mixin} css3-prefix

@mixin transition($properties...) {

  @if length($properties) >= 1 {
    @include css3-prefix('transition', $properties);
  } @else {
    @include css3-prefix('transition', "all 0.2s ease-in-out 0s");
  }
}

@mixin hvr_icon_back() {

  .icon_prepend .MuiSvgIcon-root {
    @include transform(translateZ(0));
    @include transition(transform 0.1s ease-out)
  }

  &:hover,
  &:focus,
  &:active {

    .icon_prepend .MuiSvgIcon-root {
      @include transform(translateX(-4px));
    }

  }

}


/* Icon Bounce */

@mixin hvr_icon_bounce() {

  //.hvr-icon-bounce {
  //  display: inline-block;
  //  vertical-align: middle;
  //  -webkit-transform: perspective(1px) translateZ(0);
  //  transform: perspective(1px) translateZ(0);
  //  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  //  -webkit-transition-duration: 0.3s;
  //  transition-duration: 0.3s;
  //}


  .MuiSvgIcon-root {
    @include transition(transform 0.3s ease-out)
  }

  //.hvr-icon-bounce:hover .hvr-icon, .hvr-icon-bounce:focus .hvr-icon, .hvr-icon-bounce:active .hvr-icon {
  //  -webkit-transform: scale(1.5);
  //  transform: scale(1.5);
  //  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  //  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  //}

  &:hover,
  &:focus,
  &:active {

    .MuiSvgIcon-root {
      @include transform(scale(1.5));
      @include transition(cubic-bezier(0.47, 2.02, 0.31, -0.36));
    }

  }


}


/// Box Shadow
/// @param {Size} $x [2px] - X
/// @param {Size} $y [2px] - Y
/// @param {Size} $blur [5px] - Blur
/// @param {Color} $color [rgba(0,0,0,.4)] - Color
/// @param {Boolean} $inset - Inset

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
  @if ($inset != "") {
    @include css3-prefix('box-shadow', $inset $x $y $blur $color);
  } @else {
    @include css3-prefix('box-shadow', $x $y $blur $color);
  }
}


@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin smUp {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin smDown {
  @media not all and (min-width: $sm) {
    @content;
  }
}

@mixin mdUp {
  @media (min-width: $md) {
    @content;
  }
}

@mixin mdDown {
  @media not all and (min-width: $md) {
    @content;
  }
}

@mixin lgUp {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin lgDown {
  @media not all and (min-width: $lg) {
    @content;
  }
}

@mixin xlUp {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin xlDown {
  @media not all and (min-width: $xl) {
    @content;
  }
}

@mixin size($w,$h) {
  width: $w + px;
  height: $h + px;
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin slick_dots_li() {
  button:before {
    font-size: 10px;
  }

  &:hover,
  &.slick-active {
    button:before {
      color: $theme_color2;
    }
  }
}


@mixin brand() {
  //font-size: 14px;
  font-weight: 600;
  @include text-shorten;
}

@mixin product_item_text_block() {
  padding: 0;
  //font-weight: bold;
  @include text-shorten;
  font-weight: 600;
}

@mixin show_product_cart() {
  cursor: pointer;
  display: contents;

  &:hover {
    .brand {
      color: darken($theme_color2, 10%);
    }
  }
}

@mixin article() {
  //font-weight: 700;
  font-weight: 600;
  color: $theme_color2;
}

@mixin article_container {
  //font-size: 14px;
  @include flexbox;
  @include justify-content(space-between)
}


@mixin border_type1() {
  border: $border_type1;
}

@mixin flex_box_vertical_center() {
  @include flexbox;
  @include align-items(center);
}

@mixin flex_horizontal_and_vertical_center() {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
}

@mixin flex_align($horizontal,$vertical) {
  @include flexbox;
  @include justify-content($horizontal);
  @include align-items($vertical);
}

@mixin border_rad_type1() {
  border-radius: 2px;
}

@mixin grid_column_size($count) {
  max-width: calc(100% / #{$count});
  //flex-basis: calc(100% / #{$count});
  @include flex-basis(calc(100% / #{$count}));
}

@mixin full_screen_column($media,$count) {
  @media (min-width: $media) {
    @include grid_column_size($count)
  }
}